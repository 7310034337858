var render = function () {
  var _vm$$v, _vm$$v$investmentData, _vm$$v$investmentData2, _vm$$v2, _vm$$v2$investmentDat, _vm$$v2$investmentDat2, _vm$$v3, _vm$$v3$investmentDat, _vm$$v3$investmentDat2, _vm$$v4, _vm$$v4$investmentDat, _vm$$v4$investmentDat2, _vm$$v5, _vm$$v5$investmentDat, _vm$$v5$investmentDat2, _vm$$v6, _vm$$v6$investmentDat, _vm$$v6$investmentDat2, _vm$$v7, _vm$$v7$investmentDat, _vm$$v7$investmentDat2, _vm$$v8, _vm$$v8$investmentDat, _vm$$v8$investmentDat2, _vm$$v9, _vm$$v9$investmentDat, _vm$$v9$investmentDat2, _vm$$v10, _vm$$v10$investmentDa, _vm$$v10$investmentDa2, _vm$$v11, _vm$$v11$investmentDa, _vm$$v11$investmentDa2, _vm$$v12, _vm$$v12$investmentDa, _vm$$v12$investmentDa2, _vm$$v13, _vm$$v13$investmentDa, _vm$$v13$investmentDa2, _vm$$v14, _vm$$v14$investmentDa, _vm$$v14$investmentDa2, _vm$$v15, _vm$$v15$investmentDa, _vm$$v15$investmentDa2, _vm$$v16, _vm$$v16$investmentDa, _vm$$v16$investmentDa2, _vm$$v17, _vm$$v17$investmentDa, _vm$$v17$investmentDa2, _vm$$v19, _vm$$v19$investmentDa, _vm$$v19$investmentDa2;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "add-new-investor-form"
  }, [_vm.skip3rdStep ? _c('div', {
    staticClass: "content"
  }, [_c('sygni-loader', {
    attrs: {
      "isLoading": true
    }
  })], 1) : _c('div', {
    staticClass: "content"
  }, [_c('portal', {
    attrs: {
      "to": "actions"
    }
  }, [_vm.viewType === 'previewInvestor' ? _c('GenproxButton', {
    staticClass: "outline gn-secondary",
    attrs: {
      "icon": _vm.PencilSquareIcon,
      "outline": true
    },
    on: {
      "click": function click($event) {
        _vm.$router.push("/".concat(_vm.$route.path.includes('company') ? 'company' : 'fund', "/investor/").concat(_vm.investorId, "/edit/form/step-one"));
      }
    }
  }, [_vm._v("Edit")]) : _vm._e()], 1), _c('sygni-container-title', {
    staticClass: "mb-3"
  }, [_vm.viewType === 'newInvestor' ? _c('span', [_vm._v("Add new investor")]) : _vm._e(), _vm.viewType === 'editInvestor' ? _c('span', [_vm._v("Edit investor")]) : _vm._e(), _vm.viewType === 'verifyInvestor' ? _c('span', [_vm._v("Authorize your data")]) : _vm._e(), _vm.viewType === 'previewInvestor' ? _c('span', [_vm._v("Investor details")]) : _vm._e()]), _c('div', {
    staticClass: "content__title "
  }, [_vm._v(" Residential address ")]), _vm.displayField('residential_address_street') ? _c('sygni-input', {
    staticClass: "secondary",
    attrs: {
      "label": 'STREET',
      "disabled": _vm.isReadOnly,
      "validation": (_vm$$v = _vm.$v) === null || _vm$$v === void 0 ? void 0 : (_vm$$v$investmentData = _vm$$v.investmentData) === null || _vm$$v$investmentData === void 0 ? void 0 : (_vm$$v$investmentData2 = _vm$$v$investmentData.residentialAddress) === null || _vm$$v$investmentData2 === void 0 ? void 0 : _vm$$v$investmentData2.street
    },
    model: {
      value: _vm.investmentData.residentialAddress.street,
      callback: function callback($$v) {
        _vm.$set(_vm.investmentData.residentialAddress, "street", $$v);
      },
      expression: "investmentData.residentialAddress.street"
    }
  }) : _vm._e(), _c('div', {
    staticClass: "d-flex flex-row"
  }, [_vm.displayField('residential_address_house_number') ? _c('sygni-input', {
    class: ['secondary', _vm.displayField('residential_address_flat_number') ? 'mr-4' : ''],
    attrs: {
      "label": 'HOUSE NO.',
      "disabled": _vm.isReadOnly,
      "validation": (_vm$$v2 = _vm.$v) === null || _vm$$v2 === void 0 ? void 0 : (_vm$$v2$investmentDat = _vm$$v2.investmentData) === null || _vm$$v2$investmentDat === void 0 ? void 0 : (_vm$$v2$investmentDat2 = _vm$$v2$investmentDat.residentialAddress) === null || _vm$$v2$investmentDat2 === void 0 ? void 0 : _vm$$v2$investmentDat2.houseNumber
    },
    model: {
      value: _vm.investmentData.residentialAddress.houseNumber,
      callback: function callback($$v) {
        _vm.$set(_vm.investmentData.residentialAddress, "houseNumber", $$v);
      },
      expression: "investmentData.residentialAddress.houseNumber"
    }
  }) : _vm._e(), _vm.displayField('residential_address_flat_number') ? _c('sygni-input', {
    staticClass: "secondary",
    attrs: {
      "label": 'FLAT NO.',
      "disabled": _vm.isReadOnly,
      "validation": (_vm$$v3 = _vm.$v) === null || _vm$$v3 === void 0 ? void 0 : (_vm$$v3$investmentDat = _vm$$v3.investmentData) === null || _vm$$v3$investmentDat === void 0 ? void 0 : (_vm$$v3$investmentDat2 = _vm$$v3$investmentDat.residentialAddress) === null || _vm$$v3$investmentDat2 === void 0 ? void 0 : _vm$$v3$investmentDat2.flatNumber
    },
    model: {
      value: _vm.investmentData.residentialAddress.flatNumber,
      callback: function callback($$v) {
        _vm.$set(_vm.investmentData.residentialAddress, "flatNumber", $$v);
      },
      expression: "investmentData.residentialAddress.flatNumber"
    }
  }) : _vm._e()], 1), _c('div', [_vm.displayField('residential_address_voivodeship') ? _c('sygni-input', {
    staticClass: "secondary",
    attrs: {
      "label": 'VOIVODESHIP',
      "disabled": _vm.isReadOnly,
      "validation": (_vm$$v4 = _vm.$v) === null || _vm$$v4 === void 0 ? void 0 : (_vm$$v4$investmentDat = _vm$$v4.investmentData) === null || _vm$$v4$investmentDat === void 0 ? void 0 : (_vm$$v4$investmentDat2 = _vm$$v4$investmentDat.residentialAddress) === null || _vm$$v4$investmentDat2 === void 0 ? void 0 : _vm$$v4$investmentDat2.voivodeship
    },
    model: {
      value: _vm.investmentData.residentialAddress.voivodeship,
      callback: function callback($$v) {
        _vm.$set(_vm.investmentData.residentialAddress, "voivodeship", $$v);
      },
      expression: "investmentData.residentialAddress.voivodeship"
    }
  }) : _vm._e()], 1), _c('div', [_vm.displayField('residential_address_county') ? _c('sygni-input', {
    staticClass: "secondary",
    attrs: {
      "label": 'COUNTY',
      "disabled": _vm.isReadOnly,
      "validation": (_vm$$v5 = _vm.$v) === null || _vm$$v5 === void 0 ? void 0 : (_vm$$v5$investmentDat = _vm$$v5.investmentData) === null || _vm$$v5$investmentDat === void 0 ? void 0 : (_vm$$v5$investmentDat2 = _vm$$v5$investmentDat.residentialAddress) === null || _vm$$v5$investmentDat2 === void 0 ? void 0 : _vm$$v5$investmentDat2.county
    },
    model: {
      value: _vm.investmentData.residentialAddress.county,
      callback: function callback($$v) {
        _vm.$set(_vm.investmentData.residentialAddress, "county", $$v);
      },
      expression: "investmentData.residentialAddress.county"
    }
  }) : _vm._e()], 1), _c('div', [_vm.displayField('residential_address_commune') ? _c('sygni-input', {
    staticClass: "secondary",
    attrs: {
      "label": 'COMMUNE',
      "disabled": _vm.isReadOnly,
      "validation": (_vm$$v6 = _vm.$v) === null || _vm$$v6 === void 0 ? void 0 : (_vm$$v6$investmentDat = _vm$$v6.investmentData) === null || _vm$$v6$investmentDat === void 0 ? void 0 : (_vm$$v6$investmentDat2 = _vm$$v6$investmentDat.residentialAddress) === null || _vm$$v6$investmentDat2 === void 0 ? void 0 : _vm$$v6$investmentDat2.commune
    },
    model: {
      value: _vm.investmentData.residentialAddress.commune,
      callback: function callback($$v) {
        _vm.$set(_vm.investmentData.residentialAddress, "commune", $$v);
      },
      expression: "investmentData.residentialAddress.commune"
    }
  }) : _vm._e()], 1), _vm.displayField('residential_address_city') ? _c('sygni-input', {
    staticClass: "secondary",
    attrs: {
      "label": 'CITY',
      "disabled": _vm.isReadOnly,
      "validation": (_vm$$v7 = _vm.$v) === null || _vm$$v7 === void 0 ? void 0 : (_vm$$v7$investmentDat = _vm$$v7.investmentData) === null || _vm$$v7$investmentDat === void 0 ? void 0 : (_vm$$v7$investmentDat2 = _vm$$v7$investmentDat.residentialAddress) === null || _vm$$v7$investmentDat2 === void 0 ? void 0 : _vm$$v7$investmentDat2.city
    },
    model: {
      value: _vm.investmentData.residentialAddress.city,
      callback: function callback($$v) {
        _vm.$set(_vm.investmentData.residentialAddress, "city", $$v);
      },
      expression: "investmentData.residentialAddress.city"
    }
  }) : _vm._e(), _c('div', [_vm.displayField('residential_address_zip_code') ? _c('sygni-input', {
    staticClass: "secondary",
    attrs: {
      "label": 'ZIP CODE',
      "disabled": _vm.isReadOnly,
      "validation": (_vm$$v8 = _vm.$v) === null || _vm$$v8 === void 0 ? void 0 : (_vm$$v8$investmentDat = _vm$$v8.investmentData) === null || _vm$$v8$investmentDat === void 0 ? void 0 : (_vm$$v8$investmentDat2 = _vm$$v8$investmentDat.residentialAddress) === null || _vm$$v8$investmentDat2 === void 0 ? void 0 : _vm$$v8$investmentDat2.zipCode
    },
    model: {
      value: _vm.investmentData.residentialAddress.zipCode,
      callback: function callback($$v) {
        _vm.$set(_vm.investmentData.residentialAddress, "zipCode", $$v);
      },
      expression: "investmentData.residentialAddress.zipCode"
    }
  }) : _vm._e()], 1), _vm.displayField('residential_address_country') ? _c('sygni-select', {
    staticClass: "secondary",
    attrs: {
      "options": _vm.globalSelects.countries,
      "label": 'COUNTRY',
      "searchable": true,
      "placeholder": 'Choose',
      "disabled": _vm.isReadOnly,
      "validation": (_vm$$v9 = _vm.$v) === null || _vm$$v9 === void 0 ? void 0 : (_vm$$v9$investmentDat = _vm$$v9.investmentData) === null || _vm$$v9$investmentDat === void 0 ? void 0 : (_vm$$v9$investmentDat2 = _vm$$v9$investmentDat.mailingAddress) === null || _vm$$v9$investmentDat2 === void 0 ? void 0 : _vm$$v9$investmentDat2.country
    },
    model: {
      value: _vm.investmentData.residentialAddress.country,
      callback: function callback($$v) {
        _vm.$set(_vm.investmentData.residentialAddress, "country", $$v);
      },
      expression: "investmentData.residentialAddress.country"
    }
  }) : _vm._e(), _c('div', {
    staticClass: "d-flex flex-row"
  }, [_c('div', {
    staticClass: "content__title"
  }, [_vm._v(" Mailing address ")]), _c('sygni-checkbox', {
    staticClass: "secondary ml-auto same-as-residential-button",
    attrs: {
      "label": 'SAME AS RESIDENTIAL',
      "disabled": _vm.isReadOnly
    },
    model: {
      value: _vm.investmentData.isMailingAddressSameResidential,
      callback: function callback($$v) {
        _vm.$set(_vm.investmentData, "isMailingAddressSameResidential", $$v);
      },
      expression: "investmentData.isMailingAddressSameResidential"
    }
  })], 1), !_vm.investmentData.isMailingAddressSameResidential ? [_vm.displayField('mailing_address_street') ? _c('sygni-input', {
    staticClass: "secondary",
    attrs: {
      "label": 'STREET',
      "disabled": _vm.isReadOnly,
      "validation": (_vm$$v10 = _vm.$v) === null || _vm$$v10 === void 0 ? void 0 : (_vm$$v10$investmentDa = _vm$$v10.investmentData) === null || _vm$$v10$investmentDa === void 0 ? void 0 : (_vm$$v10$investmentDa2 = _vm$$v10$investmentDa.mailingAddress) === null || _vm$$v10$investmentDa2 === void 0 ? void 0 : _vm$$v10$investmentDa2.street
    },
    model: {
      value: _vm.investmentData.mailingAddress.street,
      callback: function callback($$v) {
        _vm.$set(_vm.investmentData.mailingAddress, "street", $$v);
      },
      expression: "investmentData.mailingAddress.street"
    }
  }) : _vm._e(), _c('div', {
    staticClass: "d-flex flex-row"
  }, [_vm.displayField('mailing_address_house_number') ? _c('sygni-input', {
    class: ['secondary', _vm.displayField('mailing_address_flat_number') ? 'mr-4' : ''],
    attrs: {
      "label": 'HOUSE NO.',
      "disabled": _vm.isReadOnly,
      "validation": (_vm$$v11 = _vm.$v) === null || _vm$$v11 === void 0 ? void 0 : (_vm$$v11$investmentDa = _vm$$v11.investmentData) === null || _vm$$v11$investmentDa === void 0 ? void 0 : (_vm$$v11$investmentDa2 = _vm$$v11$investmentDa.mailingAddress) === null || _vm$$v11$investmentDa2 === void 0 ? void 0 : _vm$$v11$investmentDa2.houseNumber
    },
    model: {
      value: _vm.investmentData.mailingAddress.houseNumber,
      callback: function callback($$v) {
        _vm.$set(_vm.investmentData.mailingAddress, "houseNumber", $$v);
      },
      expression: "investmentData.mailingAddress.houseNumber"
    }
  }) : _vm._e(), _vm.displayField('mailing_address_flat_number') ? _c('sygni-input', {
    staticClass: "secondary",
    attrs: {
      "label": 'FLAT NO.',
      "disabled": _vm.isReadOnly,
      "validation": (_vm$$v12 = _vm.$v) === null || _vm$$v12 === void 0 ? void 0 : (_vm$$v12$investmentDa = _vm$$v12.investmentData) === null || _vm$$v12$investmentDa === void 0 ? void 0 : (_vm$$v12$investmentDa2 = _vm$$v12$investmentDa.mailingAddress) === null || _vm$$v12$investmentDa2 === void 0 ? void 0 : _vm$$v12$investmentDa2.flatNumber
    },
    model: {
      value: _vm.investmentData.mailingAddress.flatNumber,
      callback: function callback($$v) {
        _vm.$set(_vm.investmentData.mailingAddress, "flatNumber", $$v);
      },
      expression: "investmentData.mailingAddress.flatNumber"
    }
  }) : _vm._e()], 1), _c('div', [_vm.displayField('mailing_address_voivodeship') ? _c('sygni-input', {
    staticClass: "secondary",
    attrs: {
      "label": 'VOIVODESHIP',
      "disabled": _vm.isReadOnly,
      "validation": (_vm$$v13 = _vm.$v) === null || _vm$$v13 === void 0 ? void 0 : (_vm$$v13$investmentDa = _vm$$v13.investmentData) === null || _vm$$v13$investmentDa === void 0 ? void 0 : (_vm$$v13$investmentDa2 = _vm$$v13$investmentDa.mailingAddress) === null || _vm$$v13$investmentDa2 === void 0 ? void 0 : _vm$$v13$investmentDa2.voivodeship
    },
    model: {
      value: _vm.investmentData.mailingAddress.voivodeship,
      callback: function callback($$v) {
        _vm.$set(_vm.investmentData.mailingAddress, "voivodeship", $$v);
      },
      expression: "investmentData.mailingAddress.voivodeship"
    }
  }) : _vm._e()], 1), _c('div', [_vm.displayField('mailing_address_county') ? _c('sygni-input', {
    staticClass: "secondary",
    attrs: {
      "label": 'COUNTY',
      "disabled": _vm.isReadOnly,
      "validation": (_vm$$v14 = _vm.$v) === null || _vm$$v14 === void 0 ? void 0 : (_vm$$v14$investmentDa = _vm$$v14.investmentData) === null || _vm$$v14$investmentDa === void 0 ? void 0 : (_vm$$v14$investmentDa2 = _vm$$v14$investmentDa.mailingAddress) === null || _vm$$v14$investmentDa2 === void 0 ? void 0 : _vm$$v14$investmentDa2.county
    },
    model: {
      value: _vm.investmentData.mailingAddress.county,
      callback: function callback($$v) {
        _vm.$set(_vm.investmentData.mailingAddress, "county", $$v);
      },
      expression: "investmentData.mailingAddress.county"
    }
  }) : _vm._e()], 1), _c('div', [_vm.displayField('mailing_address_commune') ? _c('sygni-input', {
    staticClass: "secondary",
    attrs: {
      "label": 'COMMUNE',
      "disabled": _vm.isReadOnly,
      "validation": (_vm$$v15 = _vm.$v) === null || _vm$$v15 === void 0 ? void 0 : (_vm$$v15$investmentDa = _vm$$v15.investmentData) === null || _vm$$v15$investmentDa === void 0 ? void 0 : (_vm$$v15$investmentDa2 = _vm$$v15$investmentDa.mailingAddress) === null || _vm$$v15$investmentDa2 === void 0 ? void 0 : _vm$$v15$investmentDa2.commune
    },
    model: {
      value: _vm.investmentData.mailingAddress.commune,
      callback: function callback($$v) {
        _vm.$set(_vm.investmentData.mailingAddress, "commune", $$v);
      },
      expression: "investmentData.mailingAddress.commune"
    }
  }) : _vm._e()], 1), _vm.displayField('mailing_address_city') ? _c('sygni-input', {
    staticClass: "secondary",
    attrs: {
      "label": 'CITY',
      "disabled": _vm.isReadOnly,
      "validation": (_vm$$v16 = _vm.$v) === null || _vm$$v16 === void 0 ? void 0 : (_vm$$v16$investmentDa = _vm$$v16.investmentData) === null || _vm$$v16$investmentDa === void 0 ? void 0 : (_vm$$v16$investmentDa2 = _vm$$v16$investmentDa.mailingAddress) === null || _vm$$v16$investmentDa2 === void 0 ? void 0 : _vm$$v16$investmentDa2.city
    },
    model: {
      value: _vm.investmentData.mailingAddress.city,
      callback: function callback($$v) {
        _vm.$set(_vm.investmentData.mailingAddress, "city", $$v);
      },
      expression: "investmentData.mailingAddress.city"
    }
  }) : _vm._e(), _c('div', [_vm.displayField('mailing_address_zip_code') ? _c('sygni-input', {
    staticClass: "secondary",
    attrs: {
      "label": 'ZIP CODE',
      "disabled": _vm.isReadOnly,
      "validation": (_vm$$v17 = _vm.$v) === null || _vm$$v17 === void 0 ? void 0 : (_vm$$v17$investmentDa = _vm$$v17.investmentData) === null || _vm$$v17$investmentDa === void 0 ? void 0 : (_vm$$v17$investmentDa2 = _vm$$v17$investmentDa.mailingAddress) === null || _vm$$v17$investmentDa2 === void 0 ? void 0 : _vm$$v17$investmentDa2.zipCode
    },
    on: {
      "blur": function blur($event) {
        var _vm$$v18;

        (_vm$$v18 = _vm.$v) === null || _vm$$v18 === void 0 ? void 0 : _vm$$v18.$touch();
      }
    },
    model: {
      value: _vm.investmentData.mailingAddress.zipCode,
      callback: function callback($$v) {
        _vm.$set(_vm.investmentData.mailingAddress, "zipCode", $$v);
      },
      expression: "investmentData.mailingAddress.zipCode"
    }
  }) : _vm._e()], 1), _vm.displayField('mailing_address_country') ? _c('sygni-select', {
    staticClass: "secondary mb-3",
    attrs: {
      "options": _vm.globalSelects.countries,
      "label": 'COUNTRY',
      "searchable": true,
      "placeholder": 'Choose',
      "disabled": _vm.isReadOnly,
      "validation": (_vm$$v19 = _vm.$v) === null || _vm$$v19 === void 0 ? void 0 : (_vm$$v19$investmentDa = _vm$$v19.investmentData) === null || _vm$$v19$investmentDa === void 0 ? void 0 : (_vm$$v19$investmentDa2 = _vm$$v19$investmentDa.mailingAddress) === null || _vm$$v19$investmentDa2 === void 0 ? void 0 : _vm$$v19$investmentDa2.country
    },
    model: {
      value: _vm.investmentData.mailingAddress.country,
      callback: function callback($$v) {
        _vm.$set(_vm.investmentData.mailingAddress, "country", $$v);
      },
      expression: "investmentData.mailingAddress.country"
    }
  }) : _vm._e()] : _vm._e(), _vm.viewType === 'editInvestor' ? _c('div', {
    staticClass: "add-new-investor-form__propagate mb-4"
  }, [_c('sygni-checkbox', {
    staticClass: "secondary propagate",
    attrs: {
      "value": _vm.propagate,
      "removeWatcher": true,
      "label": "PROPAGATE THE DATA"
    },
    on: {
      "changedValue": _vm.setPropagate
    }
  }), _c('p', {
    class: ['add-new-investor-form__note', !_vm.propagate ? 'hidden' : '']
  }, [_c('span', {
    staticClass: "text-danger"
  }, [_vm._v("Important!")]), _vm._v(" Remember that this action will result in changes across all Legal Entities")])], 1) : _vm._e(), _vm.viewType !== 'previewInvestor' ? _c('GenproxButton', {
    staticClass: "filled gn-primary button secondary mb-4 mt-2",
    attrs: {
      "loading": _vm.loading
    },
    on: {
      "click": function click($event) {
        return _vm.goNext();
      }
    }
  }, [_vm._v("Save")]) : _vm._e(), _c('div', {
    staticClass: "cancel-button",
    on: {
      "click": function click($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v("Go back")])], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }